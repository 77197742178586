import React, { useEffect, useState } from "react";
import "./tender-details-child-sidebar.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TenderDetailsProps } from "../main/TenderDetailsView";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RaiseUser } from "../../../hooks/raiseUser";
import { CurrentPlanInStore, RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { Tender } from "../../../types/tender";
import {
  addUserInteraction,
  removeUserInteraction,
  useAddUserInteractionMutation,
  useRemoveUserInteractionMutation,
  UserInteraction,
  UserInteractionInput,
} from "../../../hooks/slices/userSlice";
import { Feature, FeatureName } from "../../../consts/features";
import { useTranslation } from "react-i18next";
import { FeatureUtil } from "../../../utils/features";
import { LabelTenderDto } from "../../../hooks/slices/labelSlice";
import { LabelCategory } from "../../../types/label";
import { UserInteractionType } from "../../../consts/UserInteractionType";
import { useGetMatchesQuery } from "../../../hooks/slices/tenderDetailsSlice";
import { LanguageParser } from "../../../consts/languages";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  CompanyIcon23,
  CompanyIcon23Blue,
  DownloadBlueIcon,
  LabelBlueIcon,
  MatchBlueIcon,
  ShareBlueIcon,
  SparklesBlueIcon,
  StarBlueFillIcon,
  StarBlueIcon,
  TranslateBlueIcon,
  TrashBlueFillIcon,
  TrashBlueIcon,
} from "../../../components/icons";
import { Labels } from "../../search/results/body/Labels";
import { SharePopup } from "./SharePopup";
import { AddBesixProjectButton } from "./AddBesixProjectButton";
import { S3FileUtil } from "../../../utils/s3Files";
import { MultilingualFieldUtil } from "../../../utils/multilingualfield";
import { t } from "i18next";
import { AnimatePresence } from "framer-motion";
import { DownloadFullProject } from "../../../components/downloadFullProject/DownloadFullProject";
import {motion} from "framer-motion";
import {
  CompanyDto, CompanyWithSharedProjects,
  ScreeningParams,
  useGetPotentialCompetitorsOrPartnersQuery,
} from "../../../hooks/slices/companySlice";
import {FADE_IN_RIGHT} from "../../../consts/FramerMotionVariants";

export const TenderDetailsSidebar: React.FC<TenderDetailsProps> = ({ tender, languageIso }) => {
  const dispatch = useAppDispatch();
  const userUuid = useAppSelector((state) => state.user.uuid);
  const userInStore = RaiseUser();
  // const userTenderInteractionsInStore: UserInteractionsInStore = UserInteractions();
  const currentPlanInStore: CurrentPlanInStore = RaiseCurrentPlan(); //navbar fetches these
  if (userInStore.error) dispatch(showErrorPopup(userInStore.error));
  if (currentPlanInStore.error) dispatch(showErrorPopup(currentPlanInStore.error));
  else if (
    userUuid &&
    userInStore.user &&
    userInStore.user.userInteractions !== undefined &&
    currentPlanInStore.currentPlan?.features !== undefined
  ) {
    return (
      <TenderDetailsSidebarView
        tender={tender}
        languageIso={languageIso}
        userUuid={userUuid}
        userInteractions={userInStore.user.userInteractions}
        features={currentPlanInStore.currentPlan?.features}
      />
    );
  }
  return <></>;
};

export interface TenderDetailsSidebarViewProps {
  tender: Tender;
  languageIso: string;
  userUuid: string;
  userInteractions: UserInteraction[];
  features: Feature[];
}

export const TenderDetailsSidebarView: React.FC<TenderDetailsSidebarViewProps> = ({
  tender,
  languageIso,
  userUuid,
  userInteractions,
  features,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const userInStore = RaiseUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDownloadFullProject, setShowDownloadFullProject] = useState<boolean>(false);
  const [showMatches, setShowMatches] = useState<boolean>(false);
  const [showSharePopup, setShowSharePopup] = useState<boolean>(searchParams.get("share") === "true");
  const closeSharePopup = () => setShowSharePopup(false);

  function toggleShowMatches() {
    setShowMatches(!showMatches);
  }

 let labels: LabelTenderDto[] | undefined = useAppSelector((state) => state.tenderSearch.responseLabels);
  const getLabels = (tenderUuid: string): LabelTenderDto[] => {
    if (labels !== undefined) return labels.filter((l) => l.tenderUuid === tenderUuid);
    else return [];
  };
  const getLabelCategories = (): LabelCategory[] => {
    if (userInStore.user && userInStore.user.labelCategories) return userInStore.user.labelCategories;
    else return [];
  };
  const showSimilarTenders = () => {
    let subjectCodes = tender.mainSubjectCodes ?? [];
    let regionCodes = (tender.regionCodes ?? []).map((r) => (r.length > 3 ? r.slice(0, 3) : r));
    navigate(
      "/search" +
        "?similar_tender_uuid=" +
        tender.uuid +
        // + "&region_codes=" + regionCodes.join(",")
        "&subject_codes=" +
        subjectCodes.join(",") +
        "&similar_tender_tag=" +
        MultilingualFieldUtil.translate(tender.title, languageIso)
    );
  };
  //todo: this duplicates the logic from the search pages, find a way around this duplication
  const [
    addUserInteractionToDb,
    { data: userInteractionsPlusOne, isError: addUserInteractionsError, error: addUserInteractionsErrorObject },
  ] = useAddUserInteractionMutation();
  const [
    removeUserInteractioToDb,
    { data: userInteractionsMinusOne, isError: removeUserInteractionsError, error: removeUserInteractionsErrorObject },
  ] = useRemoveUserInteractionMutation();
  useEffect(() => {
    if (addUserInteractionsError && addUserInteractionsErrorObject)
      dispatch(showErrorPopup(addUserInteractionsErrorObject));
    else if (removeUserInteractionsError && removeUserInteractionsErrorObject)
      dispatch(showErrorPopup(removeUserInteractionsErrorObject));
  }, [
    addUserInteractionsError,
    addUserInteractionsErrorObject,
    removeUserInteractionsError,
    removeUserInteractionsErrorObject,
  ]);
  const updateUserInteraction = (tenderUuid: string, isSelected: boolean, interactionType: UserInteractionType) => {
    let newInteraction: UserInteraction = { uuid: tenderUuid, interactionType: interactionType };
    let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: newInteraction };
    if (!isSelected) {
      addUserInteractionToDb(interactionInput); //update DB
      dispatch(addUserInteraction(interactionInput.interaction)); //update state store
    }
    if (isSelected) {
      removeUserInteractioToDb(interactionInput); //update DB
      dispatch(removeUserInteraction(interactionInput.interaction)); //update state store
    }
  };

  let translations: string[] = getLanguages(tender);
  let tenderIsLead = userInteractions
    .filter((i) => i.interactionType === UserInteractionType.MAKE_LEAD)
    .map((i) => i.uuid)
    .includes(tender.uuid);
  let tenderIsDeleted = userInteractions
    .filter((i) => i.interactionType === UserInteractionType.DELETE_OPPORTUNITY)
    .map((i) => i.uuid)
    .includes(tender.uuid);
  useEffect(() => {
    // Runs after the first render() lifecycle
    let makeLead = searchParams.get("add_to_shortlist") === "true";
    if (makeLead && !tenderIsLead) updateUserInteraction(tender.uuid, tenderIsLead, UserInteractionType.MAKE_LEAD);
  }, []);
  const [increasedSideBarWidth, setIncreasedSideBarWidth] = useState<boolean>(false);
  const contractingAuthorityIsBelgian = tender.contractingAuthority?.address?.countryIso === "BE";
  const contractingAuthorityHasCompanyPage = tender.contractingAuthority?.vatNumber !== undefined;
  const tenderHasDownloadableFiles = S3FileUtil.getDownloadableFiles(tender);
  return (
    <>
      <div>
        {showDownloadFullProject && (
          <DownloadFullProject
            setShowDownloadFullProjects={setShowDownloadFullProject}

            archiveFileName={S3FileUtil.createArchiveFileName(tender, languageIso)}
          />
        )}
        <AnimatePresence>
          <motion.div className="child-sidebar-design"
                      variants={FADE_IN_RIGHT}
                      initial={"hidden"}
                      animate={"visible"}
                      style={{
                        width: increasedSideBarWidth ? "350px" : "",
                        transition: "width 0.2s ease-in-out",
                      }}>
            <AnimatePresence>
              {showSharePopup && (
                <SharePopup tenderUuid={tender.uuid} language={languageIso} closeSharePopup={closeSharePopup} />
              )}
            </AnimatePresence>
            <ul>
              {contractingAuthorityIsBelgian && contractingAuthorityHasCompanyPage && FeatureUtil.hasFeature(features, FeatureName.SCREENING) &&
                  <ScreeningButton
                      tender={tender}
                      languageIso={languageIso}
                      toggleIncreaseSidebarWidth={() => setIncreasedSideBarWidth(!increasedSideBarWidth)}
                  />
              }
              <ShortlistButton
                isShortlisted={tenderIsLead}
                toggle={() => updateUserInteraction(tender.uuid, tenderIsLead, UserInteractionType.MAKE_LEAD)}
              />
              <DeleteOpportunityButton
                isDeleted={tenderIsDeleted}
                toggle={() =>
                  updateUserInteraction(tender.uuid, tenderIsDeleted, UserInteractionType.DELETE_OPPORTUNITY)
                }
              />
              <li>
                <div className={"center-align"}>
                  <LabelBlueIcon />
                </div>
                <div className={"center-align"}>
                  <span>
                    <Labels tenderUuid={tender.uuid} getLabels={getLabels} getLabelCategories={getLabelCategories} />
                  </span>
                </div>
              </li>
              <li data-testid={"share-button"}>
                <div className={"center-align"}>
                  <ShareBlueIcon />
                </div>
                <div className={"center-align"} onClick={() => setShowSharePopup(true)}>
                  <span>{t("detailsSideBar.share")}</span>
                </div>
              </li>
              <li>
                <div className={"center-align"}>
                  <TranslateBlueIcon />
                </div>
                <div className={"center-align"}>
                  {translations.length === 1 && <span>{t("detailsSideBar.language") + ": " + translations[0]}</span>}
                  {translations.length > 1 && (
                    <span>
                      <span>{t("detailsSideBar.languages") + ": "}</span>
                      {translations.map((iso, i) => (
                        <span key={i} data-cy={"change-language-button"}>
                          <a href={location.pathname + "?language=" + iso}>
                            {i < translations.length - 1 ? iso + ", " : iso}
                          </a>
                        </span>
                      ))}
                    </span>
                  )}
                </div>
              </li>
              {tender.hasMultilingualVector && (
                <li onClick={showSimilarTenders} data-testid={"show-similar-tenders-button"}>
                  <div className={"center-align"}>
                    <SparklesBlueIcon />
                  </div>
                  <div className={"center-align"}>
                    <span>{t("detailsSideBar.showSimilarTenders")}</span>
                  </div>
                </li>
              )}
              {tenderHasDownloadableFiles && (
                <li data-testid={"download-addenda-button"}>
                  <div className={"center-align"}>
                    <DownloadBlueIcon />
                  </div>
                  <div className={"center-align"}>
                    <span onClick={() => setShowDownloadFullProject(true)}>
                      {t("detailsSideBar.downloadFullProject")}
                    </span>
                  </div>
                </li>
              )}
              {FeatureUtil.hasFeature(features, FeatureName.BESIX_FORCE) && (
                <AddBesixProjectButton
                  tenderUuid={tender.uuid}
                  userUuid={userUuid}
                  data-testid={"add-besix-project-button"}
                />
              )}
              <li data-testid={"show-matches-button"}>
                <div className={"center-align"}>
                  <MatchBlueIcon />
                </div>
                <div className={"center-align"}>
                  <span onClick={toggleShowMatches}>{t("detailsSideBar.match")}</span>
                </div>
              </li>
              {showMatches && <Matches tenderUuid={tender.uuid} languageIso={languageIso} />}
            </ul>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

const ScreeningButton: React.FC<{ tender: Tender; languageIso: string; toggleIncreaseSidebarWidth: () => void }> = ({
  tender,
  languageIso,
  toggleIncreaseSidebarWidth,
}) => {
  const [competitorsOrPartners, setCompetitorsOrPartners] = useState<CompanyWithSharedProjects[] | undefined>(undefined);
  const [fetch, setFetch] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const {t} = useTranslation();
  let getParams: ScreeningParams = { tenderUuid: tender.uuid, languageIso: languageIso };
  const { data, isFetching, isSuccess, isError, error, isLoading } = useGetPotentialCompetitorsOrPartnersQuery(
    (fetch ? getParams : undefined) ?? skipToken
  );
  const fetchIfNeededAndToggleOpen = () => {
    if (competitorsOrPartners === undefined) setFetch(true);
    else {
      if (competitorsOrPartners.length > 0) {
        toggleIncreaseSidebarWidth();
        setOpen(!open);
      }
    }

  };
  useEffect(() => {
    if (data && isSuccess) {
      setCompetitorsOrPartners(data.companies);
      setFetch(false);
      if (data.companies.length > 0) {
        toggleIncreaseSidebarWidth();
        setOpen(true);
      }
    }
  }, [data, isSuccess]);

  return (
    <>
      <li title={t("detailsSideBar.screeningTitle")}>
        <div className={"center-align jump-up"}>
          <CompanyIcon23Blue />
        </div>
        <div className={"center-align"} data-testid={"remove-lead-button"} onClick={fetchIfNeededAndToggleOpen}>
          <span>{isLoading ? t("detailsSideBar.screeningButton") + " ..." : t("detailsSideBar.screeningButton")}</span>
        </div>
      </li>
      {open && competitorsOrPartners && (
        <>
          {competitorsOrPartners.map((c, i) => (
            <li key={c.company.uuid} onClick={() => navigate("/company/" + c.company.uuid)} className={"screening-result-button"}>
              <div>
                <CompanyIcon23 />
              </div>
              <div>
                <span>{c.company.name + " (" + c.sharedProjects + ")"}</span>
              </div>
            </li>
          ))}
        </>
      )}
    </>
  );
};

const ShortlistButton: React.FC<{ isShortlisted: boolean; toggle: () => void }> = ({ isShortlisted, toggle }) => {
  const {t} = useTranslation();
  return (
    <li onClick={toggle}>
      {isShortlisted && (
        <>
          <div className={"center-align jump-up"}>
            <StarBlueFillIcon />
          </div>
          <div className={"center-align"} data-testid={"remove-lead-button"}>
            <span>{t("detailsSideBar.removeLead")}</span>
          </div>
        </>
      )}
      {!isShortlisted && (
        <>
          <div className={"center-align"}>
            <StarBlueIcon />
          </div>
          <div className={"center-align"} data-testid={"add-lead-button"}>
            <span title={t("detailsSideBar.makeLeadTitle")}>{t("detailsSideBar.makeLead")}</span>
          </div>
        </>
      )}
    </li>
  );
};

const DeleteOpportunityButton: React.FC<{ isDeleted: boolean; toggle: () => void }> = ({ isDeleted, toggle }) => {
  return (
    <li onClick={toggle}>
      {isDeleted && (
        <>
          <div className={"center-align jump-up"}>
            <TrashBlueFillIcon />
          </div>
          <div className={"center-align"} data-testid={"un-delete-opportunity-button"}>
            <span>{t("detailsSideBar.undeleteOpportunity")}</span>
          </div>
        </>
      )}
      {!isDeleted && (
        <>
          <div className={"center-align"}>
            <TrashBlueIcon />
          </div>
          <div className={"center-align"} data-testid={"delete-opportunity-button"}>
            <span>{t("detailsSideBar.deleteOpportunity")}</span>
          </div>
        </>
      )}
    </li>
  );
};

interface MatchesProps {
  tenderUuid: string;
  languageIso: string;
}

const Matches: React.FC<MatchesProps> = ({ tenderUuid, languageIso }) => {
  const { data, isFetching, isSuccess, isError, error } = useGetMatchesQuery({ uuid: tenderUuid } ?? skipToken);
  let {t} = useTranslation()
  // render different content based on the result of the query
  let renderedContent: JSX.Element = <></>;
  if (isFetching) {
    renderedContent = <div>...</div>;
  } else if (isSuccess && data) {
    renderedContent = (
      <>
        {data.subjectCodes && data.subjectCodes.length > 0 && <Row content={t("savedSearchSettings.subjectCodes") +  ": " + data.subjectCodes.join(", ")} />}
        {data.regionCodes && data.regionCodes.length > 0 && <Row content={t("savedSearchSettings.regionCodes") +  ": " + data.regionCodes.join(", ")} />}
        {data.accreditations && data.accreditations.length > 0 && (
          <Row content={t("savedSearchSettings.accreditations") +  ": " + data.accreditations.join(", ")} />
        )}
        {data.highlights && data.highlights.length > 0 && <Row content={t("savedSearchSettings.keyWords") +  ": " + data.highlights.join(", ")} />}
      </>
    );
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError && error) {
      dispatch(showErrorPopup(error));
    }
  }, [isSuccess, data, isError, error, dispatch]);
  return renderedContent;
};

interface RowProps {
  content: string;
}

const Row: React.FC<RowProps> = ({ content }) => {
  return (
    <li>
      <div>{/*<img src={StarIcon} alt="St"/>*/}</div>
      <div>
        <span>{content}</span>
      </div>
    </li>
  );
};

function getLanguages(tender: Tender): string[] {
  let languagesPresent = [];
  if (tender.title != null) {
    for (const iso of LanguageParser.isos()) {
      if (tender.title[iso.toLowerCase() + "value"] != null) languagesPresent.push(iso);
    }
  }
  return languagesPresent;
}
