import React from 'react';
import styles from './index.module.scss';
import {Tender} from '../../../../types/tender';
import {MultilingualFieldUtil} from "../../../../utils/multilingualfield";
import { FlagsForTender } from '../../../../components/flags/flags';
import { t } from "i18next";
import { useTranslation } from 'react-i18next';

export interface RelatedTenderPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
    tender: Tender;
    languageIso?: string;
    id?: string;
}

export const RelatedTenderPreview: React.FC<RelatedTenderPreviewProps> = ({
    tender,
    languageIso='NL',
    id='',
}) => {
    const { t, i18n } = useTranslation();
    const companyName = MultilingualFieldUtil.translate(
        tender.contractingAuthority?.officialName,
        languageIso,
    );
    const tenderTitle = MultilingualFieldUtil.translate(
        tender.title,
        languageIso,
    );
    const tenderDescription = MultilingualFieldUtil.translate(
        tender.description,
        languageIso,
    );
    const deadline = tender.deadline;
    const awardDate = tender.awardDate;
    const publicationDate = tender.publicationDate;
    return (<div
        className={styles['container']}
        id={id}
    >
        <h2>
            <FlagsForTender tender={tender} />
            {companyName}
        </h2>
        <h3>
            <span>{tenderTitle}</span> 
            <br 
            /> {tenderDescription} 
        </h3>
        <h4>{
            (deadline && (t("publications.deadlineTitle") + ': ') + deadline?.split('T')[0])
            || (awardDate && (t("publications.awardDate") + ': ') + awardDate?.split('T')[0])
            || (publicationDate && (t("publications.publicationDate") + ': ') + publicationDate?.split('T')[0])
        }</h4>
    </div>);
};