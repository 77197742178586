import {    TFunction, 
} from "i18next";
import      React, {
            useEffect,
            useState,
} from "react";
import      styles 
  from "./layout.module.scss";
import {    CompanyExperienceProgressCircle,
            CompanyLotValueProgressCircle,
            CompanyOrientationProgressCircle,
            CompanyWinRateProgressCircle,
} from "./graphs";
import {    TabPanelBuyers, 
            TabPanelCompetitors, 
            TabPanelInfo, 
            TabPanelTenders, 
            TabPanelTendersFocus, 
} from "./panels";
import {    FlagIcon, 
} from "../../components/flags/flags";
import {    FollowOrUnfollowCompany, 
} from "../../components/followOrUnfollowCompany";  
import {    CompanyIcon23, 
            LocationIcon, 
            WebsiteIcon,
} from "../../components/icons";
import {    IconWithLink, 
} from "../../components/iconWithLink";
import {    IconWithTextAndCopyButton,
} from "../../components/iconWithTextAndCopyButton";
import {    SkeletonSection, 
} from "../../components/skeletonSection";
import {    Country, 
} from "../../consts/countries";
import {    UserInteractionType, 
} from "../../consts/UserInteractionType";
import {    BuyersResponse, 
            CompanyDto,     
            CompanyPageDto, 
            CompetitorsResponse, 
            RecentTenderDto, 
} from "../../hooks/slices/companySlice";
import {    LabelTenderDto, 
} from "../../hooks/slices/labelSlice";
import {    SearchResponse, 
} from "../../hooks/slices/tenderSearchSlice";
import {    UserState, 
} from "../../hooks/slices/userSlice";
import {    LabelCategory, 
} from "../../types/label";



export interface LayoutProps {
    isGovernment: boolean;
    pageSizeTenders: number;
    pageSizeCompanies: number;
    t : TFunction;
    user : UserState | null;
    labels : LabelTenderDto[] | null;
    languageIso : string | null;
    companyUuid : string | null;
    companyIsFollowed : boolean | null;
    companyPageData : CompanyPageDto | null;
  
    tendersWonPage : number;
    setTendersWonPage : React.Dispatch<React.SetStateAction<number>>;             
    tendersWon : SearchResponse | null;
  
    tendersParticipatedInPage : number;
    setTendersParticipatedInPage : React.Dispatch<React.SetStateAction<number>>;
    tendersParticipatedIn : SearchResponse | null;

    buyersPage : number;
    setBuyersPage : React.Dispatch<React.SetStateAction<number>>;
    buyers : BuyersResponse | null;

    competitorsPage : number;
    setCompetitorsPage : React.Dispatch<React.SetStateAction<number>>;
    competitors : CompetitorsResponse | null;

    tendersContractedOpenPage : number;
    setTendersContractedOpenPage : React.Dispatch<React.SetStateAction<number>>;
    tendersContractedOpen : SearchResponse | null;

    tendersContractedClosedPage : number;
    setTendersContractedClosedPage : React.Dispatch<React.SetStateAction<number>>;
    tendersContractedClosed : SearchResponse | null;
}
export const Layout: React.FC<LayoutProps> = ({
    isGovernment,
    pageSizeTenders,
    pageSizeCompanies,
    t,
    user,
    labels,
    languageIso,
    companyUuid,
    companyIsFollowed,
    companyPageData,
    tendersWonPage,             setTendersWonPage,              tendersWon,
    tendersParticipatedInPage,  setTendersParticipatedInPage,   tendersParticipatedIn,
    buyersPage,                 setBuyersPage,                  buyers,
    competitorsPage,            setCompetitorsPage,             competitors,
    tendersContractedOpenPage,  setTendersContractedOpenPage,   tendersContractedOpen,
    tendersContractedClosedPage,setTendersContractedClosedPage, tendersContractedClosed,
}) => {
    const [activeMainTab, setActiveMainTab] = useState<MainTabType>('tendering');
    const [activeSubTab, setActiveSubTab] = useState<string>( (isGovernment===false)
    ?   'tendersWon'
    :   (companyPageData?.tendersContractedOpen.length ?? 0 > 0) 
        ?   'tendersContractedOpen'
        :   (companyPageData?.tendersContractedClosed.length ?? 0 > 0) 
            ?   'tendersContractedClosed'
            :   'tendersFocus'
    );

    useEffect(() => {
        setActiveMainTab('tendering');
        setActiveSubTab((isGovernment===false) ? 'tendersWon' : 'tendersContractedClosed');
        setTendersWonPage(0);
        setTendersParticipatedInPage(0);
        setBuyersPage(0);
        setCompetitorsPage(0);
        setTendersContractedOpenPage(0);
        setTendersContractedClosedPage(0);
    }, [companyUuid]);



    const userUuid : string | null
        = user?.uuid || null;
    const getLabels : (tenderUuid: string) => LabelTenderDto[]
        = createGetLabelsFunction(labels);
    const getLabelCategories : () => LabelCategory[]
        = createGetLabelCategoriesFunction(user);
    const leads : string[] | null 
        = getLeads(user);
    const deletedOpportunities : string[] | null
        = getDeletedOpportunities(user);
    const company : CompanyDto | null
        = companyPageData?.company || null;
    const tendersWonMinimal : RecentTenderDto[] | null
        = companyPageData?.tendersWon || null;
    const tendersParticipatedInMinimal : RecentTenderDto[] | null
        = companyPageData?.tendersParticipatedIn || null;
    const tendersLostMinimal : RecentTenderDto[] | null
        = getTendersLostMinimal(tendersParticipatedInMinimal,tendersWonMinimal);
    const tendersContractedOpenMinimal : RecentTenderDto[] | null
        = companyPageData?.tendersContractedOpen || null;
    const tendersContractedClosedMinimal : RecentTenderDto[] | null
        = companyPageData?.tendersContractedClosed || null;
    const allTendersContractedMinimal : RecentTenderDto[] | null
        = getAllTendersContractedMinimal(tendersContractedOpenMinimal,tendersContractedClosedMinimal,);

    const RecentlyWon = <TabPanelTenders
        currentPage={tendersWonPage ?? 0}
        totalHits={companyPageData?.tendersWon.length ?? 0}
        itemsPerPage={pageSizeTenders}
        setPage={setTendersWonPage}
        tenders={tendersWon}
        languageIso={languageIso ?? "EN"}
        leads={leads}
        deletedOpportunities={deletedOpportunities}
        getLabels={getLabels}
        getLabelCategories={getLabelCategories}
    />;

    const RecentlyParticipatedIn = <TabPanelTenders
        currentPage={tendersParticipatedInPage ?? 0}
        totalHits={companyPageData?.tendersParticipatedIn.length ?? 0}
        itemsPerPage={pageSizeTenders}
        setPage={setTendersParticipatedInPage}
        tenders={tendersParticipatedIn}
        languageIso={languageIso ?? "EN"}
        leads={leads}
        deletedOpportunities={deletedOpportunities}
        getLabels={getLabels}
        getLabelCategories={getLabelCategories}
    />;

    const TendersContractedOpen = <TabPanelTenders
        currentPage={tendersContractedOpenPage ?? 0}
        totalHits={companyPageData?.tendersContractedOpen.length ?? 0}
        itemsPerPage={pageSizeTenders}
        setPage={setTendersContractedOpenPage}
        tenders={tendersContractedOpen}
        languageIso={languageIso ?? "EN"}
        leads={leads}
        deletedOpportunities={deletedOpportunities}
        getLabels={getLabels}
        getLabelCategories={getLabelCategories}
    />;

    const TendersContractedClosed = <TabPanelTenders
        currentPage={tendersContractedClosedPage ?? 0}
        totalHits={companyPageData?.tendersContractedClosed.length ?? 0}
        itemsPerPage={pageSizeTenders}
        setPage={setTendersContractedClosedPage}
        tenders={tendersContractedClosed}
        languageIso={languageIso ?? "EN"}
        leads={leads}
        deletedOpportunities={deletedOpportunities}
        getLabels={getLabels}
        getLabelCategories={getLabelCategories}
    />;

    const MainBuyers = <TabPanelBuyers  
        buyers={buyers}
        originalCompanyName={company?.name ?? null}
        companyType={"contracting authority"}
        currentPage={buyersPage}
        itemsPerPage={pageSizeCompanies}
        setPage={setBuyersPage}
    />

    const Info = (companyPageData !== null)
        ? <TabPanelInfo companyPage={companyPageData}/>
        : <></>;

    const Competitors = <TabPanelCompetitors
        competitors={competitors}
        originalCompanyName={company?.name ?? null}
        companyType={"company"}
        currentPage={competitorsPage}
        itemsPerPage={pageSizeCompanies}
        setPage={setCompetitorsPage}
    />

    const Focus = <TabPanelTendersFocus
        mainActivities={companyPageData?.mainActivities ?? []}
        t={t}
    />;

    const showTenderingSubTabsBar = (companyPageData===null)
    ?   true
    :   entityHasTenderingContent(isGovernment, companyPageData);

    useEffect(() => {
        setDefaultTabsAndSubtabs(isGovernment, companyPageData, setActiveMainTab, setActiveSubTab);
    }, [isGovernment, companyPageData]);

    return (
    <div className={styles['page-container']}>
        <div className={styles['header-container']}>
            <div className={styles['header-left-container']}>
                <HeaderLeftTitle company={company} userUuid={userUuid} companyIsFollowed={companyIsFollowed}/>
                <HeaderLeftInfoList company={company} />
                <div className={styles['header-left-vertical-spacer']}/> </div>
            <div className={styles['header-right-container']}>
                <HeaderRightGraphCompanyWinRate companyPageData={companyPageData} 
                                                tendersWon={tendersWonMinimal} 
                                                tendersLost={tendersLostMinimal}/>
                <HeaderRightGraphCompanyLotValue companyPageData={companyPageData}
                                                 tenders={ isGovernment 
                                                    ? allTendersContractedMinimal ?? []
                                                    : tendersWonMinimal ??  [] }/>
                <HeaderRightGraphCompanyOrientation  companyPageData={companyPageData}
                                                     tenders={ isGovernment 
                                                        ? allTendersContractedMinimal ?? []
                                                        : tendersWonMinimal ??  [] }/>
                <HeaderRightGraphCompanyExperience companyPageData={companyPageData}
                                                    tenders={ isGovernment
                                                        ? allTendersContractedMinimal ?? []
                                                        : tendersWonMinimal ??  [] }/> </div> </div>
        <div className={styles['maintabs-container']} >
            { (isGovernment===false) 
            ?<> {companyHasTenderingContent(companyPageData) && <MainTabTendering t={t} companyPageData={companyPageData} isActive={ activeMainTab === 'tendering' } onClick={() => setActiveMainTab('tendering')}/>}
                <MainTabsCompanyInformation t={t} companyPageData={companyPageData} isActive={ activeMainTab === 'info' } onClick={() => setActiveMainTab('info')}/>
                {hasCompetitorsContent(companyPageData) && <MainTabCompetitors t={t} companyPageData={companyPageData} isActive={ activeMainTab === 'competitors' } onClick={() => setActiveMainTab('competitors')}/> }
            </>
            :<> {governmentHasTenderingContent(companyPageData) && <MainTabTendering t={t} companyPageData={companyPageData} isActive={ activeMainTab === 'tendering' } onClick={() => setActiveMainTab('tendering')}/>}
                <MainTabsCompanyInformation t={t} companyPageData={companyPageData} isActive={ activeMainTab === 'info' } onClick={() => setActiveMainTab('info')}/>
            </>
            } </div>
        <div className={styles['subtabs-container']}> {activeMainTab === "tendering" && showTenderingSubTabsBar &&
            <div className={styles['subtab-bar']}>
            { (isGovernment===false)
            ?<> <SubTabTendersWon t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersWon'} onClick={() => setActiveSubTab('tendersWon')}/>
                <SubTabTendersParticipatedIn t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersParticipatedIn'} onClick={() => setActiveSubTab('tendersParticipatedIn')}/>
                <SubTabBuyer t={t} companyPageData={companyPageData} isActive={activeSubTab === 'buyers'} onClick={() => setActiveSubTab('buyers')}/>
                <SubTabTendersFocus t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersFocus'} onClick={() => setActiveSubTab('tendersFocus')}/> 
            </>
            :<> <SubTabTendersContractedOpen t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersContractedOpen'} onClick={() => setActiveSubTab('tendersContractedOpen')}/>
                <SubTabTendersContractedClosed t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersContractedClosed'} onClick={() => setActiveSubTab('tendersContractedClosed')}/>
                <SubTabTendersFocus t={t} companyPageData={companyPageData} isActive={activeSubTab === 'tendersFocus'} onClick={() => setActiveSubTab('tendersFocus')}/>
            </>
            }</div>} </div>
        <div className={styles['tabpanel-container']}>{ (isGovernment===false)
            ?<> { (activeMainTab === 'tendering') && <>
                    {(activeSubTab === 'tendersWon') && RecentlyWon}
                    {(activeSubTab === 'tendersParticipatedIn') && RecentlyParticipatedIn}
                    {(activeSubTab === 'buyers') && MainBuyers}
                    {(activeSubTab === 'tendersFocus') && Focus}
                </>}
                { (activeMainTab === 'info') && Info}
                { (activeMainTab === 'competitors') && Competitors}
            </>
            :<> { (activeMainTab === 'tendering') && <>
                    {(activeSubTab === 'tendersContractedOpen') && TendersContractedOpen}
                    {(activeSubTab === 'tendersContractedClosed') && TendersContractedClosed}
                    {(activeSubTab === 'tendersFocus') && Focus}
                </>}
                { (activeMainTab === 'info') && Info}
            </>
        }</div>
    </div>);
};


/**
 * Skeletons
 */
const SkeletonProgressCircle: React.FC = () => {
    return (<>
        <SkeletonSection width="100px" height={"100px"} margin={'0'} borderRadius={'50%'}/>
    </>)
}

const SkeletonMainTab: React.FC<{label:string}> = ({
    label,
}) => {
    return (<div className={styles['maintab']}>
        <SkeletonSection width="64px" height={'10px'} margin={'5px 0'} />
    </div>);
}

const SkeletonSubTab: React.FC = ({}) => {
    return (<div className={styles['subtab']}>
        <SkeletonSection width="96px" height={'20px'} margin={'0 0'} />
    </div>);
}

/**
 * Subcomponents
 */
interface HeaderLeftTitleProps {
    company : CompanyDto | null;
    userUuid : string | null;
    companyIsFollowed : boolean | null;
}
const HeaderLeftTitle : React.FC<HeaderLeftTitleProps> = ({ 
    company,
    userUuid,
    companyIsFollowed = false,
}) => {
    return (<>{ (company !== null) 
    ?   <div className={styles['header-left-title']}>
            <div className={styles['header-left-title-flag']}>
                <FlagIcon country={company?.country as Country}/></div>
            <div className={styles['header-left-title-name']}>
                {company.name}   </div>
            <div className={styles['header-left-title-legalform']}>
                {company.juridicalForm ? ` (${company.juridicalForm})` : ' '}</div>
            <div className={styles['header-left-title-followbutton']}>
                <FollowOrUnfollowCompany
                    userUuid={userUuid ?? ""}
                    companyIsFollowed={companyIsFollowed ?? false}
                    company={company}/> </div> </div>
    :   <SkeletonSection width="50%"height={20} margin={'0'} />
    }</>);
};

interface HeaderLeftInfoListProps {
    company : CompanyDto | null;
}
const HeaderLeftInfoList: React.FC<HeaderLeftInfoListProps> = ({ 
    company, 
}) => {
    let address = "";
    if (company?.street) address += company.street;
    if (company?.postalCode) address += ", " + company.postalCode;
    if (company?.city) address += " " + company.city;
    return (<>
        {company 
        ?   <div className={styles['header-left-info-list']}>
                <IconWithTextAndCopyButton 
                    icon={<CompanyIcon23/>} 
                    text={company.vatNumber.replace("BE", "")}/>
                {(address !== "") && <IconWithTextAndCopyButton 
                    icon={<LocationIcon/>} 
                    text={address}/>}
                {company.website && <IconWithLink 
                    icon={<WebsiteIcon/>} 
                    text={company.website}/>}
            </div>
        :   <div className={styles['header-left-info-list']}>
                { [...Array(3)].map((_, i) => 
                    <SkeletonSection 
                        key={i}
                        width="25%"
                        height={10}
                        margin={'5px 0'}  
                    />
                )}
            </div>
        }
    </>);
};


interface HeaderRightGraphCompanyWinRateProps {
    companyPageData : CompanyPageDto | null;
    tendersWon: RecentTenderDto[] | null;
    tendersLost: RecentTenderDto[] | null;
}
const HeaderRightGraphCompanyWinRate: React.FC<HeaderRightGraphCompanyWinRateProps> = ({
    companyPageData,
    tendersWon,
    tendersLost,
}) => {
    return (<div className={styles['header-right-graph']}>{ (companyPageData !== null)
    ?   <CompanyWinRateProgressCircle 
            tendersWon={tendersWon ?? []} 
            tendersLost={tendersLost ?? []}/>
    :   <SkeletonProgressCircle/>
    }</div>);
}
interface HeaderRightGraphCompanyLotValueProps {
    companyPageData : CompanyPageDto | null; 
    tenders : RecentTenderDto[];
}
const HeaderRightGraphCompanyLotValue: React.FC<HeaderRightGraphCompanyLotValueProps> = ({
    companyPageData,
    tenders,
}) => {
    return ( <div className={styles['header-right-graph']}>{(companyPageData !== null)
    ?   <CompanyLotValueProgressCircle tenders={tenders}/>
    :   <SkeletonProgressCircle/>
    }</div>);
}

interface HeaderRightGraphCompanyOrientationProps {
    companyPageData : CompanyPageDto | null;
    tenders : RecentTenderDto[];
}
const HeaderRightGraphCompanyOrientation: React.FC<HeaderRightGraphCompanyOrientationProps> = ({
    companyPageData,
    tenders,
}) => {
    return (<div className={styles['header-right-graph']}>{(companyPageData !== null)
    ?   <CompanyOrientationProgressCircle tenders={tenders}/>
    :   <SkeletonProgressCircle/>
    }</div>);
}

interface HeaderRightGraphCompanyExperienceProps {
    companyPageData : CompanyPageDto | null;
    tenders : RecentTenderDto[];
}
const HeaderRightGraphCompanyExperience: React.FC<HeaderRightGraphCompanyExperienceProps> = ({
    companyPageData,
    tenders,
}) => {
    return (<div className={styles['header-right-graph']}>{(companyPageData !== null)
    ?   <CompanyExperienceProgressCircle tenders={tenders}/>
    :   <SkeletonProgressCircle/>
    }</div>);
}

interface MainTabProps {
    t: TFunction;
    companyPageData : CompanyPageDto | null;
    isActive: boolean;
    onClick: () => void;
}
const MainTabTendering: React.FC<MainTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   <div    className={`${styles['maintab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}
        >
            {t("companyPage.tendering")} </div>
    :   <SkeletonMainTab label={t("companyPage.tendering")} />
    }</>);
}
const MainTabsCompanyInformation: React.FC<MainTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   <div className={`${styles['maintab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
            {t("companyPage.company_information_section_title")} </div>
    :   <SkeletonMainTab label={t("companyPage.company_information_section_title")} />
    }</>);
}
const MainTabCompetitors: React.FC<MainTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.competitors.length>0) 
        ?   <div className={`${styles['maintab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
               {`  ${t("companyPage.competitors")}  
                    (${companyPageData.competitors.length})
                `} </div>
        :   null
    :   <SkeletonMainTab label={t("companyPage.competitors")} />
    }</>);
}

interface SubTabProps {
    t: TFunction;
    companyPageData : CompanyPageDto | null;
    isActive: boolean;
    onClick: () => void;
}
const SubTabTendersWon: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.tendersWon.length>0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.recently_won_section_title")}
                    (${companyPageData.tendersWon.length})
            `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);
}
const SubTabTendersParticipatedIn: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.tendersParticipatedIn.length>0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.recently_participated_in_section_title")}
                    (${companyPageData.tendersParticipatedIn.length})
                `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);
}
const SubTabBuyer: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.buyers.length > 0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.main_buyers_section_title")}
                `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);
}
const SubTabTendersFocus: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.mainActivities.length>0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.main_activities_section_title")}
                `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);
}
const SubTabTendersContractedOpen: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.tendersContractedOpen.length>0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.contracted_open_section_title")}
                    (${companyPageData.tendersContractedOpen.length})
                `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);
}

const SubTabTendersContractedClosed: React.FC<SubTabProps> = ({
    t,
    companyPageData,
    isActive,
    onClick,
}) => {
    return (<>{ (companyPageData !== null)
    ?   (companyPageData.tendersContractedClosed.length>0)
        ?   <div className={`${styles['subtab']} ${isActive ? styles['active'] : ''}`}
                onClick={onClick}>
                {`  ${t("companyPage.contracted_closed_section_title")}
                    (${companyPageData.tendersContractedClosed.length})
                `} </div>
        :   null
    :   <SkeletonSubTab />
    }</>);

}

/**
 * Utils
 */
const getTendersLostMinimal = (
    tendersParticipatedInMinimal: RecentTenderDto[] | null,
    tendersWonMinimal: RecentTenderDto[] | null,
) : RecentTenderDto[] | null => {
    if (tendersParticipatedInMinimal && tendersWonMinimal) {
        return tendersParticipatedInMinimal
            .filter(t => t.type === "CONTRACT_AWARD")
            .filter(t => !tendersWonMinimal.map(t => t.uuid).includes(t.uuid));
    } else {
        return null;
    }
}

const getAllTendersContractedMinimal = function(
    tendersContractedOpenMinimal: RecentTenderDto[] | null,
    tendersContractedClosedMinimal: RecentTenderDto[] | null,
){
    if (tendersContractedOpenMinimal && tendersContractedClosedMinimal) {
        return [...tendersContractedOpenMinimal, ...tendersContractedClosedMinimal];
    } else if (tendersContractedOpenMinimal) {
        return tendersContractedOpenMinimal;
    } else if (tendersContractedClosedMinimal) {
        return tendersContractedClosedMinimal;
    } else {
        return null;
    }
};

const getLeads = (
    user: UserState | null,
) : string[] | null => {
    return user?.userInteractions?.filter(
        i => i.interactionType === UserInteractionType.MAKE_LEAD)
        .map(i => i.uuid) ?? null;
}

const getDeletedOpportunities = (
    user : UserState | null,
) : string[] | null => {
    return user?.userInteractions?.filter(
        i => i.interactionType === UserInteractionType.DELETE_OPPORTUNITY)
        .map(i => i.uuid) ?? null;
}

const createGetLabelsFunction = (
    labels: LabelTenderDto[] | null
) :  (tenderUuid: string)  => LabelTenderDto[] => {
    if (labels === null) return (tenderUuid: string) => [];
    const getLabels = (
        tenderUuid: string,
    ): LabelTenderDto[] => {
        return labels.filter(l => l.tenderUuid === tenderUuid);
    }
    return getLabels;
}

const createGetLabelCategoriesFunction = (
    user: UserState | null,
) : () => LabelCategory[] => {
    if (user === null) return () => [];
    const getLabelCategories = (): LabelCategory[] => {
        return (user.labelCategories !== undefined) 
            ? user.labelCategories 
            : []
        ;
    }
    return getLabelCategories;
}

const entityHasTenderingContent = (
    isGovernment: boolean,
    companyPageData: CompanyPageDto,
): boolean => {
    return isGovernment
        ? governmentHasTenderingContent(companyPageData)
        : companyHasTenderingContent(companyPageData);
}

const setDefaultTabsAndSubtabs = (
    isGovernment: boolean,
    companyPageData: CompanyPageDto | null,
    setActiveMainTab: React.Dispatch<React.SetStateAction<MainTabType>>,
    setActiveSubTab: React.Dispatch<React.SetStateAction<string>>,
): void => {
    if (companyPageData === null) return;
    if (isGovernment){
        if (hasTendersContractedOpenContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersContractedOpen');
        } else if (hasTendersContractedClosedContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersContractedClosed');
        } else if (hasMainActivitiesContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersFocus');
        } else {
            setActiveMainTab('info');
        }
    } else{
        if (hasTendersWonContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersWon');
        } else if (hasTendersParticipatedInContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersParticipatedIn');
        } else if (hasBuyersContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('buyers');
        } else if (hasMainActivitiesContent(companyPageData)){
            setActiveMainTab('tendering');
            setActiveSubTab('tendersFocus');
        } else {
            setActiveMainTab('info');
        }
    }
}
const companyHasTenderingContent = (
    companyPageData: CompanyPageDto | null,
): boolean => {
    if (companyPageData === null) return false;
    return companyPageData.tendersContractedOpen.length > 0
        || companyPageData.tendersContractedClosed.length > 0
        || companyPageData.mainActivities.length > 0;
}

const governmentHasTenderingContent = (
    companyPageData: CompanyPageDto | null,
): boolean => {
    if (companyPageData === null) return false;
    return companyPageData.tendersWon.length > 0
        || companyPageData.tendersParticipatedIn.length > 0
        || companyPageData.buyers.length > 0
        || companyPageData.mainActivities.length > 0;
}

const hasTendersContractedOpenContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.tendersContractedOpen.length > 0;
}

const hasTendersContractedClosedContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.tendersContractedClosed.length > 0;
}

const hasMainActivitiesContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.mainActivities.length > 0;
}

const hasTendersWonContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.tendersWon.length > 0;
}

const hasTendersParticipatedInContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.tendersParticipatedIn.length > 0;
}

const hasBuyersContent = (
    companyPageData: CompanyPageDto,
): boolean => {
    return companyPageData.buyers.length > 0;
}

const hasCompetitorsContent = (
    companyPageData: CompanyPageDto | null,
): boolean => {
    if (companyPageData === null) return false
    return companyPageData.competitors.length > 0;
}



/**
 * Types
 */
type MainTabType = "tendering"
    |   "info"
    |   "competitors"
    ;
type SubTabType = "tendersWon"
    |   "tendersParticipatedIn"
    |   "buyers"
    |   "tendersFocus"
    |   "tendersContractedOpen"
    |   "tendersContractedClosed"
    ;