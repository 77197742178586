import { apiSlice } from "./apiSlice";

export interface SitemapInput {
    filename: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getSitemap: build.query<string, SitemapInput>({
            query: (params: SitemapInput) => {
                return {
                    url: `/shaggy/sitemaps/` + params.filename,
                    responseHandler: "text"
                };
            },
        })
    }),
});

export const {useGetSitemapQuery} = extendedApiSlice;