import styles from "./index.module.scss";
import {ProgressCircle} from "../../../../components/progressCircle";
import React from "react";
import {useTranslation} from "react-i18next";
import {RecentTenderDto} from "../../../../hooks/slices/companySlice";
import {DateUtil} from "../../../../utils/date";
import {TFunction} from "i18next";

export interface CompanyOrientationProgressCircleProps {
    tenders: RecentTenderDto[];
}

export const CompanyOrientationProgressCircle: React.FC<CompanyOrientationProgressCircleProps> = ({tenders}) => {
    const {t} = useTranslation();
    let recentTenders: RecentTenderDto[] = tenders.filter(t => 
        DateUtil.getDateMoment(
            t.publicationDate
        ).isAfter(DateUtil.today()
        .subtract(5, 'years')
    ));
    if (recentTenders.length === 0) return <> </>;
    let tendersWithAwardType = recentTenders.filter(t => t.awardType);
    if (tendersWithAwardType.length === 0) return <> </>;
    let tendersWithPriceFocus = tendersWithAwardType.filter(t => t.awardType === "PRICE");
    let priceFocusPercentage = ( 100 / tendersWithAwardType.length ) * tendersWithPriceFocus.length;
    let qualityFocusPercentage = 100 - priceFocusPercentage;
    let progressPercentage = (priceFocusPercentage >= 50 )
        ? priceFocusPercentage 
        : qualityFocusPercentage
    ;
    return <div className={styles['container']}>
        <p className={styles['top-text']}>{t("companyPage.orientation")}</p>
        <ProgressCircle progressPercentage={progressPercentage}
                        size={100}
                        stroke={12}
                        text={getProgressCircleText(priceFocusPercentage, t)}
                        color={getFocusColor(priceFocusPercentage)}
                        invert={priceFocusPercentage < 50}
        />
        <p className={styles['bottom-text']}>{getSubtitle(priceFocusPercentage, t)}</p>
    </div>
}

const UPPER_BOUND = 66.6;
const LOWER_BOUND = 33.3;

function getProgressCircleText(priceFocusPercentage: number, t: TFunction): string {
    if (priceFocusPercentage < LOWER_BOUND) return t("companyPage.quality");
    if (priceFocusPercentage > UPPER_BOUND) return t("companyPage.price");
    return t("companyPage.balanced");
}

function getFocusColor(priceFocusPercentage: number): string {
    if (priceFocusPercentage < LOWER_BOUND) return "var(--secondary-color)";
    if (priceFocusPercentage > UPPER_BOUND) return "var(--primary-color)";
    return "var(--dark-green)";
}

function getSubtitle(priceFocusPercentage: number, t: TFunction): string {
    if (priceFocusPercentage < 50) return (100-priceFocusPercentage).toFixed(0) + "% " + t("companyPage.qualityOriented");
    else return priceFocusPercentage.toFixed(0) + "% " + t("companyPage.priceOriented");
}