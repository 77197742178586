import { PublicationInformation, Tender } from "../types/tender";
import { TFunction } from "i18next";

export namespace TenderUtil {
  export interface SortedPublicationCardProps {
    publicationInformation: PublicationInformation;
    priority: number;
    originalVersionDate?: string;
  }

  export function getPublicationInformationsSorted(tender: Tender): SortedPublicationCardProps[] {
    let components: SortedPublicationCardProps[] = createSortedPublicationCards(tender);
    let sortedPublications = sortPublicationsByDateDesc(components);
    if (isEnotApiTender(tender, sortedPublications))
      sortedPublications = filterOutEnotApiOlderVersions(sortedPublications);
    return sortedPublications;
  }

  function createSortedPublicationCards(tender: Tender): SortedPublicationCardProps[] {
    let components: SortedPublicationCardProps[] = [];
    if (tender.awardModifications != null && tender.awardModifications.length > 0) {
      for (const awardModification of tender.awardModifications) {
        if(awardModification.publicationInformation) {
          components.push({
            publicationInformation: awardModification.publicationInformation,
            priority: 7,
          });
        }
      }
    }
    if (tender.corrigendumChanges != null && tender.corrigendumChanges.length > 0) {
      for (const corrigendum of tender.corrigendumChanges) {
        if(corrigendum.publicationInformation){
          components.push({
            publicationInformation: corrigendum.publicationInformation,
            priority: 6,
          });
        }
      }
    }
    if (tender.contractAwardNotices != null && tender.contractAwardNotices.length > 0) {
      for (const contractAwardNotice of tender.contractAwardNotices) {
        if(contractAwardNotice.publicationInformation){
          components.push({
            publicationInformation: contractAwardNotice.publicationInformation,
            priority: 5,
          });
        }
      }
    }
    if (tender.openingReports != null && tender.openingReports.length > 0) {
      for (const openingReport of tender.openingReports) {
        if(openingReport.publicationInformation){
          components.push({
            publicationInformation: openingReport.publicationInformation,
            priority: 4,
          });
        }
      }
    }
    if (tender.designContests != null && tender.designContests.length > 0) {
      for (const designContest of tender.designContests) {
        if(designContest.publicationInformation){
          components.push({
            publicationInformation: designContest.publicationInformation,
            priority: 3,
          });
        }
      }
    }
    if (tender.contractNotices != null && tender.contractNotices.length > 0) {
      for (const contractNotice of tender.contractNotices) {
        if(contractNotice.publicationInformation){
          components.push({
            publicationInformation: contractNotice.publicationInformation,
            priority: 2,
          });
        }
      }
    }
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0) {
      for (const priorInformationNotice of tender.priorInformationNotices) {
        if(priorInformationNotice.publicationInformation){
          components.push({
            publicationInformation: priorInformationNotice.publicationInformation,
            priority: 1,
          });
        }
      }
    }
    if (tender.municipalDecisions != null && tender.municipalDecisions.length > 0) {
      for (const municipalDecision of tender.municipalDecisions) {
        if(municipalDecision.publicationInformation){
          components.push({
            publicationInformation: municipalDecision.publicationInformation,
            priority: 0,
          });
        }
      }
    }
    return components;
  }

  function sortPublicationsByDateDesc(publications: SortedPublicationCardProps[]): SortedPublicationCardProps[] {
    return publications
      .filter((p) => p.publicationInformation !== null)
      .sort(function (a, b) {
        if (a.publicationInformation.publicationDate === b.publicationInformation.publicationDate) {
          // Priority is only important when dates are the same
          return a.priority > b.priority ? -1 : 1;
        }
        return a.publicationInformation.publicationDate > b.publicationInformation.publicationDate ? -1 : 1;
      });
  }

  // export function getPublicationUuidsSorted(tender: Tender): string[]{
  //     let sortedPublicationUuids: string[] = getPublicationInformationsSorted(tender)
  //         .map((component, i) => (component.publicationInformation.uuid));
  //     let distinctSortedPublicationUuids: string[] = []; //corrigenda can repeat
  //     for(const uuid of sortedPublicationUuids){
  //         if (!distinctSortedPublicationUuids.includes(uuid)) distinctSortedPublicationUuids.push(uuid);
  //     } return distinctSortedPublicationUuids;
  // }

  function isEnotApiTender(tender: Tender, publications: SortedPublicationCardProps[]): boolean {
    return (
        (tender.publishers ?? []).includes("ENOT_BDA") &&
      publications.filter(
        (p) => (p.publicationInformation?.files ?? []).filter((f) => f.s3Key?.includes("E_NOTIFICATION_API")).length > 0
      ).length > 0
    );
  }

  function filterOutEnotApiOlderVersions(publicationsSortedByDateDesc: SortedPublicationCardProps[]): SortedPublicationCardProps[] {
    let mostRecentPublicationVersions: SortedPublicationCardProps[] = [];
    let publicationPlatformIds: string[] = [];
    for (const publication of publicationsSortedByDateDesc) {
      let platformPublicationId = publication.publicationInformation.platformPublicationId;
      if (publicationPlatformIds.includes(publication.publicationInformation.platformPublicationId)) {
        let changedPublication = mostRecentPublicationVersions.find(
            //the priority check here is a check for the publication type because opening reports have the same platformpublication id as their contract notices
            p => p.publicationInformation.platformPublicationId === platformPublicationId && p.priority === publication.priority
        );
        if (changedPublication != null) {
          changedPublication.originalVersionDate = publication.publicationInformation.publicationDate;
        } else {
          mostRecentPublicationVersions.push(publication);
        }
      } else {
        publicationPlatformIds.push(platformPublicationId);
        mostRecentPublicationVersions.push(publication);
      }
    } return mostRecentPublicationVersions;
  }

  export function translateSortedPublicationPriority(priority: number, t: TFunction): string {
    if (priority === 0) return t("publications.municipalDecision");
    if (priority === 1) return t("publications.priorInformationNotice");
    if (priority === 2) return t("publications.contractNotice");
    if (priority === 3) return t("publications.designContest");
    if (priority === 4) return t("publications.openingReport");
    if (priority === 5) return t("publications.contractAwardNotice");
    if (priority === 6) return t("publications.corrigendum");
    if (priority === 7) return t("publications.corrigendum");
    //'rectificatie van een gunning' doesn't fit
    else return "-";
  }
}

