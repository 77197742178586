import React 
  from "react";
import {    SkeletonSection, 
} from "../../../components/skeletonSection";

export const SkeletonPaginate : React.FC = ({
}) => { return <SkeletonSection
        width="124px"
        height="32px"
        margin={'32px auto'} 
    />; 
}