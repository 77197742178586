import  React, {
} from 'react';
import  styles
  from './index.module.scss';
import { SkeletonSection } from '../../../../components/skeletonSection';
import { SearchResponse } from '../../../../hooks/slices/tenderSearchSlice';
import { Paginate } from '../../../../components/paginate';
import { SkeletonPaginate } from '../skeletons';
import { CompetitorsResponse, CompanyDto, CompetitorDto} from '../../../../hooks/slices/companySlice';
import { CompanyOverviewCard } from '../../../../components/companyOverviewCard';


export interface TabPanelCompetitorsProps {
    competitors: CompetitorsResponse | null;
    currentPage: number ;
    itemsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    originalCompanyName: string | null;
    companyType: "contracting authority" | "company";
}
export const TabPanelCompetitors: React.FC<TabPanelCompetitorsProps> = ({
    competitors,
    currentPage, 
    itemsPerPage, 
    setPage,
    originalCompanyName,
    companyType,
}) => {
    const totalHits = competitors?.totalHits ?? null;


    return (<div className={styles['tabpanel-container']}>
        <div className="search-filter-section-alignment">
            <div className='search-main-card-show'>
                <CompetitorsList 
                    competitors={competitors} 
                    originalCompanyName={originalCompanyName} 
                    companyType={companyType}/> </div>
            <CompetitorsPagination    
                competitors={competitors?.competitors ?? null}
                currentPage={currentPage}
                totalHits={totalHits ?? 0}
                itemsPerPage={itemsPerPage}
                setPage={setPage}
            />
        </div>
    </div>);
}


/**
 * Skeletons
 */
const SkeletonCompanyCard : React.FC = ({
}) => { return <SkeletonSection
    width="100%"
    height="136px"
    margin={'0'}
/>; }

interface SkeletonCompanyCardListProps {
    numberOfSkeletons: number;
}
const SkeletonCompanyCardList : React.FC<SkeletonCompanyCardListProps> = ({
    numberOfSkeletons,
})  => {
    return <div className={styles['skeleton-company-card-list']}>
    {Array.from(Array(numberOfSkeletons).keys()).map((i) => 
        <SkeletonCompanyCard key={i} />)}
</div>}


/**
 * Sub Components
 */
interface CompetitorsPaginationProps {
    competitors: CompetitorDto[] | null;
    currentPage: number;
    totalHits: number;
    itemsPerPage: number;
    setPage: (page: number) => void;
}
const CompetitorsPagination: React.FC<CompetitorsPaginationProps> = ({
    competitors,
    currentPage,
    totalHits,
    itemsPerPage,
    setPage
}) => { return <>{ (competitors !== null)
    ?   <Paginate 
            currentPage={currentPage}
            totalHits={totalHits}
            itemsPerPage={itemsPerPage}
            setPage={setPage}
        />
    :   <SkeletonPaginate />
}</>;}

interface CompetitorsListProps {
    competitors: CompetitorsResponse | null;
    originalCompanyName: string | null;
    companyType: "contracting authority" | "company";
}
const CompetitorsList: React.FC<CompetitorsListProps> = ({
    competitors,
    originalCompanyName,
    companyType,
}) => { return <> { (competitors !== null)
    ?   <> { competitors.competitors
            .filter(b => b.sharedProjects.length>0)
            .map((competitor: CompetitorDto, i) =>
                <CompanyOverviewCard 
                    key={competitor.company.uuid} 
                    company={competitor.company} 
                    originalCompanyName={originalCompanyName ?? ""} 
                    sharedProjects={competitor.sharedProjects}
                    companyType={companyType}
                />)
            } </>
    :   <SkeletonCompanyCardList numberOfSkeletons={4} />
}
</>}