import moment, { Moment } from "moment";


export namespace DateUtil{
  //yyyy-MM-dd <-> dd-MM-yyyy
  export function formatDate(date: string | undefined): string | null{
    if (date == null) return null;
    let parts = date.split("-");
    if (parts.length !== 3) return null;
    else return parts[2] + "-" + parts[1] + "-" + parts[0];
  }
//yyyy-MM-ddTHH:mm:ss -> dd-MM-yyyy HH:mm
  export function formatDateTime(date: string | undefined): string | null{
    if (date == null) return null;
    let parts = date.split("T");
    if (parts.length !== 2) return null;
    let timeParts = parts[1].split(":");
    let datePart = formatDate(parts[0]);
    if (datePart == null) return null;
    return datePart + " " + timeParts[0] + ":" + timeParts[1];
  }

  export function today(): Moment {
    return moment();
  }

  export function todayMinusDays(amount: number): Moment {
    return moment().subtract(amount, 'days');
  }

  export function todayPlusDays(amount: number): Moment {
    return moment().add(amount, 'days');
  }

  export function todayMinusMonths(amount: number): Moment {
    return moment().subtract(amount, 'months');
  }

  export function todayPlusMonths(amount: number): Moment {
    return moment().add(amount, 'months');

  }
  export function getStartOfPreviousWeek(): Moment {
    return moment().subtract(1, 'weeks').startOf('week');
  }

  export function getEndOfPreviousWeek(): Moment {
    return moment().subtract(1, 'weeks').endOf('week');
  }

  export function todayMinusYears(amount: number): Moment {
    return moment().subtract(amount, 'years');
  }

  export function todayPlusYears(amount: number): Moment {
    return moment().add(amount, 'years');
  }

  export function getDateString(moment: Moment): string{
    return getDateStringWithFormat(moment,"YYYY-MM-DD");
  }

  export function getDateStringWithFormat(moment: Moment, format: string): string{
    return moment.format(format)
  }

  export function getDateTimeString(moment: Moment): string{
    return moment.format("YYYY-MM-DDTHH:mm")
  }

  export function validateDate(s: string){
    return moment(s, "YYYY-MM-DD", true).isValid();
  }

  export function validateDateTime(s: string | undefined){
    if (s === undefined) return false;
    return moment(s, "YYYY-MM-DDTHH:mm", true).isValid();
  }

  export function convertDateTimeStringToDateString(dateTimeString: string | undefined){
    if (dateTimeString && dateTimeString.includes("T")) return dateTimeString.split("T")[0]
    else return dateTimeString;
  }

  export function convertDateStringToDateTimeString(dateString: string | undefined){
    if (dateString) return dateString + "T00:00"
    else return dateString;
  }

  export function getDateMoment(dateString: string): Moment{
    return moment(dateString, "YYYY-MM-DD", true);
  }

  export function findDateMoment(dateString: string | undefined): Moment | undefined{
    if (dateString == null || dateString === "") return undefined;
    return moment(dateString, "YYYY-MM-DD", true);
  }

  //The estimated renewal date refers to the date of execution, not the date of the publication.
  export function convertEstimatedRenewalPublicationDateToEstimatedRenewalDate(date: string): string{
      let estimatedPublicationDate: Moment = getDateMoment(date);
      let estimatedRenewalDate: Moment = estimatedPublicationDate.clone().add(3, 'months');
      return getDateString(estimatedRenewalDate);
  }

}
