import {MultilingualField} from "../types/tender";
import {LanguageParser} from "../consts/languages";

export namespace MultilingualFieldUtil{
    export function translate(multilingualField: MultilingualField | undefined, languageIso: string) {
        if (multilingualField == null || languageIso == null) return "";
        for (const iso of LanguageParser.isos()) {
            if (languageIso.toUpperCase() === iso && multilingualField[iso.toLowerCase() + "value"] != null) return multilingualField[iso.toLowerCase() + "value"];
        }
        return anyTranslation(multilingualField);
    }

    export function anyTranslation(multilingualField: MultilingualField) {
        for (const iso of LanguageParser.isos()) {
            if (multilingualField[iso.toLowerCase() + "value"] != null) return multilingualField[iso.toLowerCase() + "value"];
        } if(multilingualField["othervalue"] != null) return multilingualField["othervalue"];
        return "";
    }

    export function optionalTranslation(multilingualField: MultilingualField | undefined, languageIso: string) {
        let translation = translate(multilingualField, languageIso);
        if (translation === "") return null;
        else return translation;
    }
}