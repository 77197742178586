import React from 'react'
import styles from './index.module.scss';
import { Tender as TenderType} from '../../../../types/tender';
import { MultilingualFieldUtil } from '../../../../utils/multilingualfield';
import { Language } from '../../../../consts/languages';
import { FlagsForTender } from '../../../flags/flags';


export interface TenderProps extends React.HTMLAttributes<HTMLDivElement> {
    tender: TenderType;
    language: Language;
}
export const Tender: React.FC<TenderProps> = ({ 
    tender,
    language,
    ...props
}) => {
    const contractingAuthorityName = MultilingualFieldUtil.translate(
        tender.contractingAuthority?.officialName,
        language,
    );
    const tenderTitle = MultilingualFieldUtil.translate(
        tender.title,
        language,
    );
    const tenderDescription = MultilingualFieldUtil.translate(
        tender.description,
        language,
    );
    const deadline = tender.deadline;
    const awardDate = tender.awardDate;
    const publicationDate = tender.publicationDate;
    return (<div 
        className={styles['tender']} 
        {...props}
    >
        <h2>
            <FlagsForTender tender={tender} />
            {contractingAuthorityName}
        </h2>
        <h3>
            <span>{tenderTitle}</span> 
            <br 
            /> {tenderDescription} 
        </h3>
        <h4>{
            deadline?.replace('T', ' ') 
            || awardDate?.replace('T', ' ') 
            || publicationDate?.replace('T', ' ')
        }</h4>
    </div>);
}