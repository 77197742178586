import React from 'react';
import styles from './index.module.scss';

import { CompanyCodeDto } from '../../../../hooks/slices/companySlice';

import { CpvUtil, CodeUtil } from '../../../../utils/codes'; 
import { TFunction } from 'react-i18next';


export interface TabPanelTendersFocusProps extends React.HTMLAttributes<HTMLDivElement> {
    mainActivities: CompanyCodeDto[];
    t: TFunction;
}
export const TabPanelTendersFocus: React.FC<TabPanelTendersFocusProps> = ({
    mainActivities,
    t,
}) => {
    return (<div className={styles["tab-panel-tenders-focus"]}> { mainActivities
        .slice().sort((a, b) => b.count - a.count)
        .map((activity, index) => (
        <div key={index} className={styles['activity']}>
            <div className={styles['cpv-icon']}>
                <img src={
                    CpvUtil.getCpvIcon(activity.cpvRoot.code)} 
                    alt={activity.cpvRoot.code} 
                />
            </div>
            <div className={styles['cpv-description']}>
                {CodeUtil.getCodeWithTranslation(
                    activity.cpvRoot, 
                    t,
                )}
            </div>
            <div className={styles['cpv-count']}>
                ({activity.count})
            </div>
        </div>
    ))
    }</div>);
}