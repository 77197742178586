import styles from "./index.module.scss";
import {ProgressCircle} from "../../../../components/progressCircle";
import React from "react";
import {useTranslation} from "react-i18next";
import {RecentTenderDto} from "../../../../hooks/slices/companySlice";
import {DateUtil} from "../../../../utils/date";
import {TFunction} from "i18next";

export interface CompanyExperienceProgressCircleProps {
    tenders: RecentTenderDto[];
    topText?: string;
    bottomText?: string;
}

export const CompanyExperienceProgressCircle: React.FC<CompanyExperienceProgressCircleProps> = ({
    tenders,
}) => {
    const {t} = useTranslation();
    let recentTenders: RecentTenderDto[] = tenders.filter(t => 
        DateUtil.getDateMoment(t.publicationDate)
            .isAfter(DateUtil.today().subtract(5, 'years')))
    ;
    if (recentTenders.length === 0) return <></>;
    return <div className={styles['container']}>
        <p className={styles['top-text']}>{t("companyPage.company_experience_title")}</p>
        <ProgressCircle progressPercentage={getCompanyExperienceProgressBarPercentage(recentTenders)}
                        size={100}
                        stroke={12}
                        text={getCompanyExperienceProgressBarText(recentTenders, t)}
                        color={"var(--amber)"}
        />
        <p className={styles['bottom-text']}>{recentTenders.length + " " + t("companyPage.company_experience_last_5_years")}</p>
    </div>
}

function getCompanyExperienceProgressBarPercentage(tenders: RecentTenderDto[]): number {
    if (tenders.length > 25) return 100;
    if (tenders.length > 15) return 80;
    if (tenders.length > 5) return 60;
    if (tenders.length > 1) return 40;
    return 20;
}

function getCompanyExperienceProgressBarText(tenders: RecentTenderDto[], t: TFunction): string {
    if (tenders.length > 25) return t("companyPage.company_experience_expert");
    if (tenders.length > 15) return t("companyPage.company_experience_experienced");
    if (tenders.length > 5) return t("companyPage.company_experience_average");
    if (tenders.length > 1) return t("companyPage.company_experience_basic");
    return t("companyPage.company_experience_beginner");
}