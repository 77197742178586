import React 
  from 'react';
import styles
  from './index.module.scss';


export interface SkeletonSectionProps {
  width: string;
  height: number | string;
  margin?: string;
  borderRadius?: string;
}

export const SkeletonSection: React.FC<SkeletonSectionProps> = ({ 
    width, 
    height, 
    margin,
    borderRadius,
}) => {
  const marginDefault = '0 0 36px 0';
  const borderRadiusDefault = '8px';

  return <div 
      className={styles["skeleton-base"]} 
      style={{
          height: typeof height === 'number' ? `${height}px` : height, 
          width:  width, 
          borderRadius: (borderRadius || borderRadiusDefault),
          margin: (margin || marginDefault),
      }} 
  />
};

