import React from 'react';
import styles from './index.module.scss';

import {CompanyDto, CompanyPageDto} from "../../../../hooks/slices/companySlice";
import {StringUtil} from "../../../../utils/strings";
import {
    CompanyDetailsContentItem,
} from "../../../../types/company";
import {CompanyUtil} from "../../../../utils/company";
import {useTranslation} from "react-i18next";
import { IconWithTextAndCopyButton } from '../../../../components/iconWithTextAndCopyButton';
import { EmailIcon, PhoneIcon, WebsiteIcon } from '../../../../components/icons';
import { IconWithLink } from '../../../../components/iconWithLink';


export interface TabPanelInfoProps extends React.HTMLAttributes<HTMLDivElement> {
    companyPage: CompanyPageDto;
}
export const TabPanelInfo: React.FC<TabPanelInfoProps> = ({ 
    children, 
    companyPage,
    ...props 
}) => {
    let {t} = useTranslation();
    const combinedActivities: string[] = CompanyUtil.getCompanyActivities(companyPage.company.activities)
        .map(activity => activity)
        .filter(StringUtil.distinct)
        .filter(activity => activity.trim() !== '')
    ;
    let accreditations: CompanyDetailsContentItem[] = getCompanyAccreditations(companyPage.company);
    let contactInformationAvailable: boolean = companyPage.company.website != null || companyPage.company.phone != null || companyPage.company.email != null;
    let address: string | undefined = CompanyUtil.getCompanyAddress(companyPage.company);
    if (!companyPage) return <></>
    return (<>
        <div className={styles["container-info"]}>
            <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_details_title")}</h1>
                <p>{companyPage.company.name}</p>
                <p>{address}</p>
                {companyPage.company.startDate && <p>{t("companyPage.company_info_start_date_title") + ": " +companyPage.company.startDate}</p>}
            </div>
            {contactInformationAvailable && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_contact_title")}</h1>
                {companyPage.company.phone && <IconWithTextAndCopyButton icon={<PhoneIcon/>} text={companyPage.company.phone}/>}
                {companyPage.company.email && <IconWithTextAndCopyButton icon={<EmailIcon/>} text={companyPage.company.email}/>}
                {companyPage.company.website && <IconWithLink icon={<WebsiteIcon/>} text={companyPage.company.website}/>}
            </div>}
            {combinedActivities.length > 0 && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_activities_title")}</h1>
                {combinedActivities.map(activity => <p>{activity}</p>)}
            </div>}

            {accreditations.length > 0 && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_accreditations_title")}</h1>
                {accreditations.map(activity => <p>{activity.value}</p>)}
            </div>}
        </div>
    </>);
}

/**
 * utils
 */
export function getCompanyAccreditations(company: CompanyDto): CompanyDetailsContentItem[]{
  const accreditations: string[] = company.activities
      .filter((a) => a.type === 'ACCREDITATION')
      .map(a => a.description);
  accreditations.sort();
  return accreditations.map(activity => ({value: activity}))
}