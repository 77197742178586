import    React 
,{        useEffect,
          useState,
} from "react";
import {  useTranslation, 
} from "react-i18next";
import {  Params, 
          useParams, 
} from "react-router-dom";
import {  skipToken, 
} from "@reduxjs/toolkit/dist/query";
import {  Layout,
} from "./layout";
import {  BuyersResponse, 
          CompanyPageDto, 
          CompetitorsResponse, 
          useGetCompanyPageBuyersQuery, 
          useGetCompanyPageCompetitorsQuery, 
          useGetCompanyPageQuery, 
} from "../../hooks/slices/companySlice";
import {  LabelTenderDto, 
} from "../../hooks/slices/labelSlice";
import {  RaiseUser, 
          UserLanguage, 
} from "../../hooks/raiseUser";
import {  useGetTenderSearchMutation, 
          TenderSearchInput,
          SearchResponse,  
} from "../../hooks/slices/tenderSearchSlice";
import {  UserInteraction, 
} from "../../hooks/slices/userSlice";
import {  SearchPhase, 
} from "../../consts/searchPhase";
import {  SearchType, 
} from "../../consts/searchType";
import {  UserInteractionType, 
} from "../../consts/UserInteractionType";

const PAGE_SIZE_TENDERS = 20;
const PAGE_SIZE_COMPANIES = 20;


export interface CompanyPageProps {
  government: boolean;
}
export const CompanyPage: React.FC<CompanyPageProps> = ({ 
  government,
}) => {
    const { t, i18n} = useTranslation();
    const params = useParams<Params>();
    const { user, isLoading: userIsLoading, error: userError } = RaiseUser();
    const languageIso = params.tenderLanguage
        ? params.tenderLanguage
        : UserLanguage(i18n.language);

    const companyUuid = params?.uuid ?? null;
    const {data: companyPageFetch, isLoading: companyPageDataIsLoading, isError: companyPageDataIsError} = useGetCompanyPageQuery({uuid:companyUuid ?? ""});
    const [companyPageData, setCompanyPageData] = useState<CompanyPageDto | null>(null);

    const [companyIsFollowed, setCompanyIsFollowed] = useState<boolean | null >(null);

    const [getTendersWon, {data: tendersWonFetch, isLoading: tendersWonIsLoading, isError: tendersWonIsError}] = useGetTenderSearchMutation();
    const [tendersWon, setTendersWon] = useState<SearchResponse | null>(null);
    const [tendersWonPage, setTendersWonPage] = useState<number>(0);

    const [getTendersParticipatedIn, {data: tendersParticipatedInFetch, isLoading: tendersParticipatedInIsLoading, isError: tendersParticipatedInIsError}] = useGetTenderSearchMutation();
    const [tendersParticipatedIn, setTendersParticipatedIn] = useState<SearchResponse | null>(null);
    const [tendersParticipatedInPage, setTendersParticipatedInPage] = useState<number>(0);

    const [ buyersPage, setBuyersPage ] = useState<number>(0);
    const { data: buyersFetch, isFetching: buyersAreLoading } = useGetCompanyPageBuyersQuery(
    companyPageData
    ? { uuid: companyUuid ?? "",
        page: buyersPage,
        pageSize: PAGE_SIZE_COMPANIES, 
    } : skipToken);
    const [buyers, setBuyers] = useState<BuyersResponse | null>(buyersFetch ?? null);

    const [competitorsPage, setCompetitorsPage] = useState<number>(0);
    const { data: competitorsFetch, isFetching: competitorsIsLoading } = useGetCompanyPageCompetitorsQuery(
      companyPageData
      ? { uuid: companyUuid ?? "",
          page: competitorsPage,
          pageSize: PAGE_SIZE_COMPANIES,
      } : skipToken);
    const [competitors, setCompetitors] = useState<CompetitorsResponse | null>(competitorsFetch ?? null);

    const [getTendersContractedOpen, {data: tendersContractedOpenFetch, isLoading: tendersContractedOpenIsLoading, isError: tendersContractedOpenIsError}] = useGetTenderSearchMutation();
    const [tendersContractedOpen, setTendersContractedOpen] = useState<SearchResponse | null>(null);
    const [tendersContractedOpenPage, setTendersContractedOpenPage] = useState<number>(0);
    
    const [getTendersContractedClosed, {data: tendersContractedClosedFetch, isLoading: tendersContractedClosedIsLoading, isError: tendersContractedClosedIsError}] = useGetTenderSearchMutation();
    const [tendersContractedClosed, setTendersContractedClosed] = useState<SearchResponse | null>(null);
    const [tendersContractedClosedPage, setTendersContractedClosedPage] = useState<number>(0);

    const [labels, setLabels] = useState<LabelTenderDto[] | null>(null);

    useEffect(() => {
      setCompanyPageData(null)
      setCompanyIsFollowed(null)
      setTendersWon(null)
      setTendersParticipatedIn(null)
      setBuyers(null)
      setCompetitors(null)
      setTendersContractedOpen(null)
      setTendersContractedClosed(null)
    }, [companyUuid]);

    useEffect(() => {
        if (!companyPageFetch) return;
        setCompanyPageData(companyPageFetch);
    }, [companyPageFetch]);

    useEffect(() => {
      if (!tendersWonFetch) return;
      setTendersWon(tendersWonFetch);
    }, [tendersWonFetch]);

    useEffect(() => {
      if (!tendersParticipatedInFetch) return;
      setTendersParticipatedIn(tendersParticipatedInFetch);
    }, [tendersParticipatedInFetch]);

    useEffect(() => {
      if (!buyersFetch) return;
      setBuyers(buyersFetch);
    }, [buyersFetch]);

    useEffect(() => {
      if (!competitorsFetch) return;
      setCompetitors(competitorsFetch);
    }, [competitorsFetch]);

    useEffect(() => {
      if (!tendersContractedOpenFetch) return;
      setTendersContractedOpen(tendersContractedOpenFetch);
    }, [tendersContractedOpenFetch]);

    useEffect(() => {
      if (!tendersContractedClosedFetch) return;
      setTendersContractedClosed(tendersContractedClosedFetch);
    }, [tendersContractedClosedFetch]);

    useEffect(() => {
        if ( (!user) || (!companyUuid===null)){ 
            setCompanyIsFollowed(null);
            return;
        }
        const userInteractions = user.userInteractions ?? [];
        const companyIsFollowed = userInteractions.some( (i:UserInteraction) => 
            i.interactionType === UserInteractionType.FOLLOW_COMPANY 
            && i.uuid === companyUuid
        );
        setCompanyIsFollowed(companyIsFollowed);
    }, [user, companyUuid]);
    
    useEffect(() => {
        if (!companyPageFetch) return;
        getTendersWon(tenderSearchInput(
            companyPageFetch.tendersWon.map((t:any) => t.uuid),
            tendersWonPage, 
            PAGE_SIZE_TENDERS,
        ));
    }
    , [companyPageFetch, tendersWonPage]);

    useEffect(() => {
        if (!companyPageFetch) return;
        getTendersParticipatedIn(tenderSearchInput(
            companyPageFetch.tendersParticipatedIn.map((t:any) => t.uuid),
            tendersParticipatedInPage, 
            PAGE_SIZE_TENDERS,
        ));
    }
    , [companyPageFetch, tendersParticipatedInPage]);

    useEffect(() => {
        if (!companyPageFetch) return;
        getTendersContractedOpen(tenderSearchInput(
            companyPageFetch.tendersContractedOpen.map((t:any) => t.uuid),
            tendersContractedOpenPage, 
            PAGE_SIZE_TENDERS,
        ));
      }
    , [companyPageFetch, tendersContractedOpenPage]);

    useEffect(() => {
        if (!companyPageFetch) return;
        getTendersContractedClosed(tenderSearchInput(
            companyPageFetch.tendersContractedClosed.map((t:any) => t.uuid),
            tendersContractedClosedPage, 
            PAGE_SIZE_TENDERS,
        ));
      }
    , [companyPageFetch, tendersContractedClosedPage]);

  return (<>
    <Layout
      isGovernment={government}
      pageSizeTenders={PAGE_SIZE_TENDERS}
      pageSizeCompanies={PAGE_SIZE_COMPANIES}

      t={t}
      user={user ?? null}
      labels={labels}
      languageIso={languageIso}
      companyUuid={companyUuid}
      companyIsFollowed={companyIsFollowed}
      companyPageData={companyPageData}
      tendersWonPage={tendersWonPage}                           setTendersWonPage={setTendersWonPage}                           tendersWon={tendersWon}
      tendersParticipatedInPage={tendersParticipatedInPage}     setTendersParticipatedInPage={setTendersParticipatedInPage}     tendersParticipatedIn={tendersParticipatedIn}
      buyersPage={buyersPage}                                   setBuyersPage={setBuyersPage}                                   buyers={buyers} 
      competitorsPage={competitorsPage}                         setCompetitorsPage={setCompetitorsPage}                         competitors={competitors}
      tendersContractedOpenPage={tendersContractedOpenPage}     setTendersContractedOpenPage={setTendersContractedOpenPage}     tendersContractedOpen={tendersContractedOpen}
      tendersContractedClosedPage={tendersContractedClosedPage} setTendersContractedClosedPage={setTendersContractedClosedPage} tendersContractedClosed={tendersContractedClosed}
    />
  </>);
};

/**
 * Utils
 */
function tenderSearchInput(
    tenderUuids: string[], 
    page: number, 
    pageSize: number
): TenderSearchInput {
  return {
    metadata: {
      searchType: SearchType.SEARCH,
      searchPhase: SearchPhase.SEARCH,
      page: page,
      pageSize: pageSize,
      sortBy: 'PUBLICATION_DATE_DESC',
      tenderUuidFilters: tenderUuids,
    },
    mainInput: {
      name: "uuid_search",
    }, filterInputs: []
  }
}

