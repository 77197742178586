import {
    CompanyActivityDto,
    CompanyDto,
    CompanyPageDto,
    CompanySuggestion,
    RecentTenderDto
} from "../hooks/slices/companySlice";
import {MultilingualFieldUtil} from "./multilingualfield";
import {StringUtil} from "./strings";
import {CodeUtil, CpvUtil} from "./codes";
import {CodeType} from "../types/code";
import {t, TFunction} from "i18next";
import { CompanyDetailsContentItem } from "../types/company";

export namespace CompanyUtil {
    export function isKboNumber(nationalIdentifier: string | null | undefined): boolean {
        let enterprise = "[0-9]{4}\\.[0-9]{3}\\.[0-9]{3}";
        let establishment = "[0-9]{1}\\.[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}";
        return nationalIdentifier != null
            && (nationalIdentifier.match(enterprise) != null || nationalIdentifier.match(establishment) != null);
    }

    export function findKboVatNumber(vat: string | undefined): string | undefined  {
        if(vat == null) return undefined;
        if(vat.match("BE\\d{4}\\.\\d{3}\\.\\d{3}")) return vat;//vat is actually a KBO number
        if(vat.match("\\d{4}\\.\\d{3}\\.\\d{3}")) return 'BE' + vat;//vat is actually a KBO number
        return undefined;
    }

    export function sortCompanySuggestionsByName(companies: CompanySuggestion[]): CompanySuggestion[] {
        return [...companies].sort((a, b) => {
            const nameA = MultilingualFieldUtil.translate(a.name, "nl");
            const nameB = MultilingualFieldUtil.translate(b.name, "nl");

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    export function getCompanyAddress(company: CompanyDto): string | undefined {
        let description = "";
        if (company.street) description += company.street;
        if (company.postalCode) description += ", " + company.postalCode;
        if (company.city) description += " " + company.city;
        return description !== "" ? description : undefined;
    }

//There are always at most two rsz activities and we're only interested in showing three entries at most, so we take the
//rsz activities first (these are more interesting) and then complete the list with btw activities that are not overlapping
//with the rsz activities.
    export function getCompanyActivities(activities: CompanyActivityDto[]): string[]{
        const rszActivities: string[] = activities
            .filter((a) => a.type === 'RSZ')
            .slice(0, 2)
            .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
        const numBtwActivities = rszActivities.length === 1 ? 2 : 1; // If there is only one RSZ activity, show 2 BTW activities, otherwise show 1
        const btwActivities: string[] = activities
            .filter((a) => a.type === 'BTW' && !rszActivities.includes((a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description)))
            .slice(0, numBtwActivities)
            .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
        return [...rszActivities, ...btwActivities].filter(StringUtil.distinct).slice(0, 3);
    }

    export function getAverageLotValue(tenders: RecentTenderDto[]): number | undefined {
        let values: number[] = [];
        tenders.forEach(t => {if(t.awardedValue) values.push(t.awardedValue)});
        if (values.length === 0) return undefined;
        let sum: number = values.reduce((a,b) => a + b);
        return sum / values.length;
    }

    export function getCpvActivities(company: CompanyPageDto, t: TFunction): string[]{
        return company.mainActivities
            .map(c => CodeUtil.getCodeTranslation(
                        CpvUtil.getCpvFullCode(c.cpvRoot.code),
                        CodeType.CPV, t
                    )) ?? [];
    }

}
