import  React 
  from 'react';
import  styles 
  from './index.module.scss';
import {    TenderCard, TenderCardConfiguration, 
} from '../../../search/results/body/TenderCard';
import {    Paginate, 
} from '../../../../components/paginate';
import {    SearchResponse, 
} from '../../../../hooks/slices/tenderSearchSlice';
import {    LabelTenderDto, 
} from '../../../../hooks/slices/labelSlice';
import {    LabelCategory, 
} from '../../../../types/label';
import { SkeletonSection } from '../../../../components/skeletonSection';
import { SkeletonPaginate } from '../skeletons';


export interface TabPanelTendersProps {
    currentPage: number;
    totalHits: number;
    itemsPerPage: number;
    setPage: (page: number) => void;
    tenders: SearchResponse | null;
    languageIso: string;
    leads: string[] | null;
    deletedOpportunities: string[] | null;
    getLabels: (tenderUuid: string) => LabelTenderDto[];
    getLabelCategories: () => LabelCategory[];
}
export const TabPanelTenders: React.FC<TabPanelTendersProps> = ({
    currentPage, 
    totalHits, 
    itemsPerPage, 
    setPage,
    tenders,
    languageIso,
    leads,
    deletedOpportunities,
    getLabels,
    getLabelCategories
}) => {
    return (<div className={styles['tabpanel-container']}>
        <div className="search-filter-section-alignment">
            <div className='search-main-card-show'>
                <TenderCardList 
                    tenders={tenders}
                    languageIso={languageIso}
                    leads={leads}
                    deletedOpportunities={deletedOpportunities}
                    getLabels={getLabels}
                    getLabelCategories={getLabelCategories}
                />
            </div>
            <TenderPagination 
                tenders={tenders}
                currentPage={currentPage}
                totalHits={totalHits}
                itemsPerPage={itemsPerPage}
                setPage={setPage}
            />
        </div>
    </div>);
}


/**
 * Skeletons
 */
const SkeletonTenderCard : React.FC = ({
}) => { return <SkeletonSection
    width="100%"
    height="256px"
    margin={'0'}
/>; }

interface SkeletonTenderCardListProps {
    numberOfSkeletons : number;
}
const SkeletonTenderCardList : React.FC<SkeletonTenderCardListProps> = ({
    numberOfSkeletons,
}) => { return <div className={styles['skeleton-tender-card-list']}>
    {Array.from(Array(numberOfSkeletons).keys()).map((i) => 
        <SkeletonTenderCard key={i} />)}
</div>}


/**
 * Sub components
 */
interface TenderCardListProps {
    tenders: SearchResponse | null;
    languageIso: string;
    leads: string[] | null;
    deletedOpportunities: string[] | null;
    getLabels: (tenderUuid: string) => LabelTenderDto[];
    getLabelCategories: () => LabelCategory[];
}
const TenderCardList: React.FC<TenderCardListProps> = ({
    tenders,
    languageIso,
    leads,
    deletedOpportunities,
    getLabels,
    getLabelCategories,
}) => {
    return <>{ (tenders !== null && languageIso !== null && leads !== null && deletedOpportunities !== null && getLabels !== null && getLabelCategories !== null)
    ?   tenders.tenders.map((hit, i) => 
            <TenderCard
                key={hit.tender.uuid}
                tenderSearchHit={hit}
                languageIso={languageIso}
                configuration={tenderCardConfiguration}
                isLead={leads ? leads.includes(hit.tender.uuid) : false}
                isDeletedOpportunity={deletedOpportunities ? deletedOpportunities.includes(hit.tender.uuid) : false}
                getLabels={getLabels}
                getLabelCategories={getLabelCategories} />)
    :   <SkeletonTenderCardList numberOfSkeletons={4} />
}</>;}

interface TenderPaginationProps {
    tenders: SearchResponse | null;
    currentPage: number;
    totalHits: number;
    itemsPerPage: number;
    setPage: (page: number) => void;
}
const TenderPagination: React.FC<TenderPaginationProps> = ({
    tenders,
    currentPage,
    totalHits,
    itemsPerPage,
    setPage
}) => { return <>{ (tenders !== null)
    ?   <Paginate 
            currentPage={currentPage}
            totalHits={totalHits}
            itemsPerPage={itemsPerPage}
            setPage={setPage}
        />
    :   <SkeletonPaginate />
}</>;}


/**
 * Configuration
 */
const tenderCardConfiguration: TenderCardConfiguration = {
    showProgress: false,
    showDescription: true,
    showCompanies: true,
    showCueHighlights: true,
    showQueryHighlights: false,
    showLatestPublicationTypeInUpperRightCorner: true,
    showPublicationDetails: true,
    showTags: true,
    showLeadsIcon: true,
    showFollowIcon: true,
    showDeleteOpportunityIcon: true,
    showProfiles: false
};