import  React, {
} from 'react';
import  styles
  from './index.module.scss';
import { SkeletonSection } from '../../../../components/skeletonSection';
import { SearchResponse } from '../../../../hooks/slices/tenderSearchSlice';
import { Paginate } from '../../../../components/paginate';
import { SkeletonPaginate } from '../skeletons';
import { BuyerDto, BuyersResponse, CompanyDto, CompetitorDto, CompetitorsResponse } from '../../../../hooks/slices/companySlice';
import { CompanyOverviewCard } from '../../../../components/companyOverviewCard';


export interface TabPanelBuyersProps {
    buyers: BuyersResponse | null;
    currentPage: number ;
    itemsPerPage: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    originalCompanyName: string | null;
    companyType: "contracting authority" | "company";
}
export const TabPanelBuyers: React.FC<TabPanelBuyersProps> = ({
    buyers,
    currentPage, 
    itemsPerPage, 
    setPage,
    originalCompanyName,
    companyType,
}) => {
    const totalHits = buyers?.totalHits ?? null;

    return (<div className={styles['tabpanel-container']}>
        <div className="search-filter-section-alignment">
            <div className='search-main-card-show'>
                <BuyersList 
                    buyers={buyers} 
                    originalCompanyName={originalCompanyName} 
                    companyType={companyType}/> </div>
            <BuyersPagination    
                buyers={buyers?.buyers ?? null}
                currentPage={currentPage}
                totalHits={totalHits ?? 0}
                itemsPerPage={itemsPerPage}
                setPage={setPage}
            />
        </div>
    </div>);
}


/**
 * Skeletons
 */
const SkeletonCompanyCard : React.FC = ({
}) => { return <SkeletonSection
    width="100%"
    height="136px"
    margin={'0'}
/>; }

interface SkeletonCompanyCardListProps {
    numberOfSkeletons: number;
}
const SkeletonCompanyCardList : React.FC<SkeletonCompanyCardListProps> = ({
    numberOfSkeletons,
})  => {
    return <div className={styles['skeleton-company-card-list']}>
    {Array.from(Array(numberOfSkeletons).keys()).map((i) => 
        <SkeletonCompanyCard key={i} />)}
</div>}


/**
 * Sub Components
 */
interface BuyersPaginationProps {
    buyers: BuyerDto[] | null;
    currentPage: number;
    totalHits: number;
    itemsPerPage: number;
    setPage: (page: number) => void;
}
const BuyersPagination: React.FC<BuyersPaginationProps> = ({
    buyers,
    currentPage,
    totalHits,
    itemsPerPage,
    setPage
}) => { return <>{ (buyers !== null)
    ?   <Paginate 
            currentPage={currentPage}
            totalHits={totalHits}
            itemsPerPage={itemsPerPage}
            setPage={setPage}
        />
    :   <SkeletonPaginate />
}</>;}

interface BuyersListProps {
    buyers: BuyersResponse | null;
    originalCompanyName: string | null;
    companyType: "contracting authority" | "company";
}
const BuyersList: React.FC<BuyersListProps> = ({
    buyers,
    originalCompanyName,
    companyType,
}) => { return <> { (buyers !== null)
    ?   <> { buyers.buyers
            .filter(b => b.sharedProjectsWon.length>0)
            .map((buyer: BuyerDto, i) =>
                <CompanyOverviewCard 
                    key={buyer.company.uuid} 
                    originalCompanyName={originalCompanyName ?? ""} 
                    company={buyer.company} 
                    projectsContracted={buyer.projectsContracted} 
                    sharedProjectsWon={buyer.sharedProjectsWon}
                    companyType={companyType}
                />)
            } </>
    :   <SkeletonCompanyCardList numberOfSkeletons={4} />
}
</>}
